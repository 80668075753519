import React from 'react'
import Image from './Image'
import Button from './Button'
import { Delete } from './Icons'
import { Link } from 'react-router-dom'

export default function PropertyCard({ id, onDelete, address, image, title, bth, bds, area, ClassName, ...props }) {
    return (
        <>
            <a href={'https://munzul.com/details/' + id} target='_blank'>
                <div className="card_outer">
                    <div className="card_column">
                        <div className="left_col property-card">
                            <Button text={<Delete />} className="delete-btn" data-bs-toggle="modal" data-bs-target="#decline_modal" onClick={onDelete} />
                            <Image placeholder="/image-not-found.png" alt='property' src={image} className='img-fluid' />
                            <div className="name">{title}</div>
                            <div className="small_heading"><i class="bi bi-geo-fill"></i> {address}</div>
                            {/* <div className="small_heading">{Service_title}</div> */}
                            <ul className='d-flex justify-content-between property_description small_heading mt-2'>
                                <li><i class="bi bi-align-top"></i> {bth} BTH</li>
                                <li><i class="bi bi-align-top"></i> {bds} BDS</li>
                                <li><i class="bi bi-square-fill"></i> {area} SQFT</li>
                            </ul>
                        </div>
                        {/* <div className="right_col">
                        <div className=" service_name">{1625}</div>
                    </div> */}
                    </div>
                </div>
            </a>
        </>
    )
}
