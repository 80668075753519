import React, { useEffect, useState } from 'react'
import EditButton from '../common/Edit_button'
import DetailsViewer from '../common/DetailsViewer'
import EditProfile from '../common/EditProfile'
import { toaster } from '../apis/commonFunctions'
import { getRequest, patchRequest } from '../apis/functions'
import APIS from '../apis/routes'
import { useDispatch } from 'react-redux'
import { setDetails } from '../redux/slices/detailsSlice'

export default function AdminProfile() {
    const [userDetails, setData] = useState({
        name: "",
        email: "",
        withdraw_days: 0,
        profile_pic: "",
        role: "",
        privillages: []
    })

    const [toggle, setToggle] = useState(false);
    const dispatcher = useDispatch()

    useEffect(() => {
        (async () => {
            try {
                const { data } = await getRequest(APIS.GET_PROFILE);
                if (data.status) {
                    setData({
                        ...data.data
                    })
                    dispatcher(setDetails(data.data))
                } else {
                    toaster(data.message, false);
                }
            } catch (err) {
                toaster(err.message, false);
            }
        })()
    }, []);

    async function updateProfile(details, file) {
        try {
            let formData = new FormData();
            formData.append('name', details.name);
            formData.append('email', details.email);
            formData.append('withdraw_days', details.withdraw_days);
            formData.append('commision_percentage[rent]', details.commision_percentage.rent)
            formData.append('commision_percentage[auction]', details.commision_percentage.auction)
            formData.append('commision_percentage[rental]', details.commision_percentage.rental)
            if (file) {
                formData.append('profile_pic', file);
            }
            for (let [key, value] of formData.entries()) {
                console.log(key, value)
            }
            const { data } = await patchRequest(APIS.UPDATE_PROFILE, formData);
            if (data.status) {
                toaster(data.message, true);
                localStorage.setItem('name', data.data.name);
                localStorage.setItem('email', data.data.email);
                localStorage.setItem('profile_pic', data?.data?.profile_pic);
                localStorage.setItem('withdraw_days', data.data.withdraw_days);
                dispatcher(setDetails(data.data))
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        <button className='d-flex align-items-center'>Profile</button>
                    </div>
                </div>
                <div className="col-lg-6 text-end">
                    <EditButton onClick={(e) => {
                        setToggle(!toggle)
                    }} />
                </div>
            </div>
            <div className="outer_div mt-4">
                {!toggle ? <DetailsViewer commision={userDetails.commision_percentage} withdraw_days={userDetails.withdraw_days} name={userDetails.name} email={userDetails.email} profile_pic={userDetails.profile_pic} /> :
                    <EditProfile commision={userDetails.commision_percentage} withdraw_days={userDetails.withdraw_days} name={userDetails.name} email={userDetails.email} profile_pic={userDetails.profile_pic} onUpdateProfile={updateProfile} />}
            </div>
        </>
    )
}