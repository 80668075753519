import React, { useState } from 'react'
import Input from '../common/Input'
import Button from '../common/Button'
import { postRequest } from '../apis/functions'
import APIS from '../apis/routes'
import { toaster } from '../apis/commonFunctions'

export default function ChangePassword() {
    const [loader,setLoader] = useState(false);
    const [password, setPassword] = useState({
        old_password: '',
        new_password: '',
        confirm_password: ''
    })

    function handleChanges(e) {
        setPassword({
            ...password,
            [e.target.name] : e.target.value.trim()
        })
    }

    async function submitForm(event){
        try {
            setLoader(true);
            const { data: { status, message } } = await postRequest(APIS.CHANGE_PASSWORD,password)
            setLoader(false);
            if (status) {
                toaster(message,true);
            } else {
                toaster(message,false)
            }
        } catch (err) {
            toaster(err.message,false)
        }
    };

    return (
        <>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-12">
                        <div className="login_area">
                            <div className="mt-5">
                                <div className="group">
                                    <Input className={'mt-2'} type={'password'} onChange={handleChanges} autoComplete={'off'} placeholder={'********'} value={password.old_password} name="old_password" label={'Old Password'} />
                                </div>
                                <div className="group mt-4">
                                    <Input className={'mt-2'} value={password.new_password} type={'password'} name="new_password" placeholder='********' onChange={handleChanges} label={'New Password'} />
                                </div>
                                <div className="group mt-4">
                                    <Input className={'mt-2'} value={password.confirm_password} type={'password'} name="confirm_password" placeholder='********' onChange={handleChanges} label={'Confirm Password'} />
                                </div>
                                <div className="site_btn mt-3">
                                    <Button loader={loader} type="submit" onClick={submitForm} text={'Change Password'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
