import './App.css';
import './Mobile.css'
import Login_Signup from './pages/Login_Signup';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Protected from './guards/Protected';
import Authentic from './guards/Authentic';
import Dashboard from './pages/Dashboard'
import CustomerManagerment from './pages/Customer_managerment'
import Payments from './pages/Payments'
import CustomerDetails from './pages/Customer_details'
import Properties from './pages/Properties'
import ReportedProperty from './pages/ReportedProperty'
import Documents from './pages/Documents'
import PrivacyPolicy from './pages/PrivacyPolicy'
import TermsCondition from './pages/TermsCondition'
import AdminProfile from './pages/AdminProfile';
import { getRequest, setRouter } from './apis/functions';
import APIS from './apis/routes';
import { useEffect } from 'react';
import { toaster } from './apis/commonFunctions';
import ChangePassword from './pages/ChangePassword'
import { useDispatch, useSelector } from 'react-redux'
import { setDetails } from './redux/slices/detailsSlice';
import AdminManagement from './pages/AdminManagement';
import CreateAdmin from './pages/CreateAdmin';
import ViewSubAdmin from './pages/ViewSubAdmin';
import PageNotFound from './pages/PageNotFound'
import ForgotPassword from './pages/ForgotPassword';
import CpmPackages from './pages/advertisement/CpmPackages';
import BidManagement from './pages/BidManagement';
import Badges from './pages/Badges';

function App() {
  const router = useNavigate();
  const dispatcher = useDispatch()
  const info = useSelector(state => state.details)
  setRouter(router);
  useEffect(() => {
    (async () => {
      try {
        const { data } = await getRequest(APIS.GET_PROFILE);
        if (data.status) {
          dispatcher(setDetails(data.data))
        } else {
          // toaster(data.message, false);
        }
      } catch (err) {
        toaster(err.message, false);
      }
    })()
  }, [])

  return (
    <>
      {/* <Login_Signup/> */}
      <Routes>
        <Route path='/login' element={<Authentic><Login_Signup /></Authentic>} />
        <Route path='/forgot-password' element={<Authentic><ForgotPassword /></Authentic>} />
        <Route path='/' element={<Protected />}>
          <Route exact path='/' element={<Dashboard />} />
          {(info.role === "SUPERADMIN") && <><Route exact path='/admin-management' element={<AdminManagement />} />
            <Route exact path='/payments' element={<Payments />} />
            <Route exact path='/badges' element={<Badges />} />
            <Route exact path='/admin-management/create' element={<CreateAdmin />} />
            <Route exact path='/admin/profile/:id' element={<ViewSubAdmin />} />
            <Route exact path='/advertisement/impressions' element={<CpmPackages />} />
          </>}

          {((info.role === "SUPERADMIN") || info.privillages.includes("USERMANAGEMENT")) && <>
            <Route exact path='/user-management/:type' element={<CustomerManagerment />} />
            <Route exact path='/user-management/details/:id' element={<CustomerDetails />} />
          </>}

          {((info.role === "SUPERADMIN") || info.privillages.includes("PROPERTYMANAGEMENT")) && <>
            <Route exact path='/properties' element={<Properties />} />
            <Route exact path='/reported-properties' element={<ReportedProperty />} />
            <Route exact path='/documents' element={<Documents />} />
          </>}

          {((info.role === "SUPERADMIN") || info.privillages.includes("CONTENTMANAGEMENT")) && <>
            <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route exact path='/terms-and-condition' element={<TermsCondition />} />
          </>}

          {((info.role === "SUPERADMIN") || info.privillages.includes("BIDMANAGEMENT")) && <>
            <Route exact path='/bid-management' element={<BidManagement />} />
          </>}

          <Route exact path='/admin/profile' element={<AdminProfile />} />
          <Route exact path='/admin/change-password' element={<ChangePassword />} />
          {/* <Route exact path='/provider-management' element={<ProviderManagement />} /> */}
        </Route>
        <Route exact path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;