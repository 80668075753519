import React, { useEffect, useState } from 'react'
import Table_profile from '../common/Table_profile'
import { Block, Delete, Eye, Loginas, Search } from '../common/Icons'
import Search_input from '../common/Search_input'
import { Link,useParams, useSearchParams } from 'react-router-dom'
import { toaster } from '../apis/commonFunctions'
import { getRequest, patchRequest, postRequest } from '../apis/functions'
import APIS from '../apis/routes'
import ConfirmationPopup from '../common/popups/ConfirmationPopup'
import NoDataRow from '../common/NoDataRow'
import Pagination from '../common/Pagination'

const TableRow = ({ name, email,onLogin, profile, contact, _id, blocked, onBlock }) => {
    return <tr>
        <td><Table_profile image={profile} name={name} /></td>
        <td style={{ textTransform: "inherit" }}>{email}</td>
        <td>{contact ? contact : "Not Provided"}</td>
        <td>
            <div className="actions">
                <button className='view p-2' onClick={onLogin}>
                    <Loginas />
                </button>
                <Link className=' view' to={"/user-management/details/" + _id}>
                    <Eye />
                </Link>
                <button className=' block' style={{ color: (blocked ? "#0F993E" : "#ff4c27") }} onClick={onBlock}>
                    <Block />
                </button>
                <button className=' delete' data-bs-toggle="modal" data-bs-target="#decline_modal">
                    <Delete />
                </button>
            </div>
        </td>
    </tr>
}

export default function Customer_managerment() {
    const [label, setLabel] = useState('User Management');
    const [users, setUsers] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const limit = 5;
    const { type } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get('search')
    useEffect(() => {
        switch (type) {
            case 'all':
                setLabel('User Management')
                break;
            case 'buyers':
                setLabel('Buyers Management')
                break;
            case 'sellers':
                setLabel('Sellers Management')
                break;
            case 'agents':
                setLabel('Agents Management')
                break;
            default:
                setLabel('User Management')
        }
    }, [type]);

    const fetchData = async (skip = 0) => {
        try {
            const { data } = await getRequest(APIS.GET_DATA + "/" + type + "?skip=" + skip + "&limit=" + limit + "&search=" + (search ?? ""));
            if (data.status) {
                setUsers([...data.data])
                setTotalCount(data.totalCount);
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const blockUnblockUser = async (id) => {
        try {
            const { data } = await patchRequest(APIS.BLOCK_USER + id, {});
            if (data.status) {
                toaster(data.message, true);
                fetchData();
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    async function generateUserToken(id){
        try{
            const {data} = await postRequest(APIS.GENERATE_TOKEN+id);
            if(data.status){
                window.open(process.env.REACT_APP_WEBSITE_URL+'/admin/login/'+data.token,'_blank')
            }else{
                toaster(data.message,false)
            }
        }catch(err){
            toaster(err.message,false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [type, search])

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        {label}
                    </div>
                </div>
                <div className="col-lg-6">
                    <Search_input placeholder={'Search here'} search={search} handleSearch={(e) => {
                        setSearchParams({
                            search: e.target.value
                        })
                    }} icon={<Search />} className='search_input' />
                </div>
            </div>
            <div className="row mt-5">
                <div className="table-responsive">
                    <table className='table table-lg'>
                        <thead className='table-light'>
                            <tr colspan="4">
                                <th>Name</th>
                                <th>Email address</th>
                                <th>Contact Number</th>
                                <th>action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length > 0 ? users.map(item => <TableRow name={item.name} email={item.email} profile={item.profile_pic} _id={item._id} blocked={item.isBlocked} onBlock={() => {
                                blockUnblockUser(item._id)
                            }} onLogin={()=>{
                                generateUserToken(item._id)
                            }} />) : <NoDataRow />}
                        </tbody>
                    </table>
                    <Pagination handlePageClick={(i) => { fetchData(i.selected * limit) }} totalCount={totalCount} limit={limit} />
                </div>
            </div>
            <ConfirmationPopup message="Do you want to delete this user ?" />
        </>
    )
}
