import React, { useEffect, useState } from 'react'
import Document from '../common/Document'
import { getRequest } from '../apis/functions';
import APIS from '../apis/routes';
import { toaster } from '../apis/commonFunctions';

export default function Documents() {
    const [documents, setDocuments] = useState([]);
    const fetchData = async () => {
        try {
            const { data } = await getRequest(APIS.GET_DOCUMENTS);
            if (data.status) {
                setDocuments([...data.data]);
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        <button className='d-flex align-items-center'>Documents</button>
                    </div>
                </div>
                {/* <div className="col-lg-6 text-end">
                    <button className='blank_btn pe-0'>
                        <Filter />
                    </button>
                </div> */}
            </div>
            <div className="row">
                {documents.length > 0 ? documents.map(item => <div className="col-lg-3 mt-4">
                    <Document title={item.propertyName} onUpdate={()=>{
                        fetchData();
                    }} image={item.image} uploaded={item.updated_at} propertyId={item.propertyId}/>
                </div>) : <p>No Data</p>}
            </div>
        </>
    )
}
