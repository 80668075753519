import React, { useEffect, useState } from 'react'
import { Cancel } from '../Icons'
import Table_profile from '../Table_profile'
import { getRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import { toaster } from '../../apis/commonFunctions'
import * as moment from 'moment'
import NoDataRow from '../NoDataRow'

const TableRow = ({ name, reason, profile, reported_on }) => {
    return <tr>
        <td><Table_profile image={profile} name={name} /></td>
        <td style={{ textTransform: "inherit" }}>{reason}</td>
        <td>{moment(reported_on).format("DD MMM, YYYY")}</td>
    </tr>
}

const Reasons = ({ id }) => {
    const [reasons, setReasons] = useState([]);

    useEffect(() => {
        if (id) {
            (async () => {
                try {
                    const { data } = await getRequest(APIS.GET_REASONS + id);
                    if (data.status) {
                        setReasons([...data.data])
                    } else {
                        toaster(data.message, false);
                    }
                } catch (err) {
                    toaster(err.message, false);
                }
            })()
        }
    }, [id])

    return (
        <div class="modal fade" id="view_reasons" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal modal-dialog-centered">
                <div class="modal-content">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="modal-body text-center">
                        <div className="modal_icon">
                            {/* <Cancel /> */}
                        </div>
                        <div className="table-responsive">
                            <table className='table table-lg'>
                                <thead className='table-light'>
                                    <tr colspan="4">
                                        <th>Name</th>
                                        <th>Reason</th>
                                        <th>Reported on</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reasons.length > 0 ? reasons.map(item => <TableRow name={item.user_name} reason={item.reason} profile={item.user_image} reported_on={item.report_time} />) : <NoDataRow />}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reasons