import React, { useState } from 'react'
import Button from './Button'
import * as moment from 'moment'
import { deleteRequest, getRequest } from '../apis/functions'
import APIS, { BASE_URL } from '../apis/routes'
import { toaster } from '../apis/commonFunctions'
import Image from './Image'
import { Delete } from './Icons'

export default function BidProperty({ title, image, uploaded, _id, className, propertyId, onUpdate, setId, ...props }) {

    return (
        <>
            <div className="card_outer">
                <div className="card_column">
                    <div className="left_col">
                        {/* <Button text={<Delete />} className="delete-btn" onClick={deleteDocument}/> */}
                        <Image placeholder="/image-not-found.png" alt='property' src={image} className='img-fluid' />
                        <div className="name">{title}</div>
                        <div className="small_heading">Uploaded on {moment(uploaded).format('DD MMM, YYYY')}</div>
                        <Button data-bs-toggle="modal" data-bs-target="#view_bids" text="View Bids" type="button" onClick={setId} />
                    </div>
                </div>
            </div>
        </>
    )
}
