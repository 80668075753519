import React, { useEffect, useState } from 'react'
import Table_profile from '../common/Table_profile'
import { Block, Delete, Eye, Loginas, Search } from '../common/Icons'
import Search_input from '../common/Search_input'
import { Link,useParams, useSearchParams } from 'react-router-dom'
import { toaster } from '../apis/commonFunctions'
import { getRequest, patchRequest, postRequest } from '../apis/functions'
import APIS from '../apis/routes'
import ConfirmationPopup from '../common/popups/ConfirmationPopup'
import NoDataRow from '../common/NoDataRow'
import Pagination from '../common/Pagination'
import BidProperty from '../common/BidProperty'
import BidPopup from '../common/popups/Bids'

const TableRow = ({ name, email,onLogin, profile, contact, _id, blocked, onBlock }) => {
    return <tr>
        <td><Table_profile image={profile} name={name} /></td>
        <td style={{ textTransform: "inherit" }}>{email}</td>
        <td>{contact ? contact : "Not Provided"}</td>
        <td>
            <div className="actions">
                <button className='view p-2' onClick={onLogin}>
                    <Loginas />
                </button>
                <Link className=' view' to={"/user-management/details/" + _id}>
                    <Eye />
                </Link>
                <button className=' block' style={{ color: (blocked ? "#0F993E" : "#ff4c27") }} onClick={onBlock}>
                    <Block />
                </button>
                <button className=' delete' data-bs-toggle="modal" data-bs-target="#decline_modal">
                    <Delete />
                </button>
            </div>
        </td>
    </tr>
}

export default function BidManagement() {
    const [label, setLabel] = useState('Bid Management');
    const [bids, setBids] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const limit = 5;
    const { type } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get('search')
    const [mainId,setMainId] = useState("");

    const fetchData = async (skip = 0) => {
        try {
            const { data } = await getRequest(APIS.BIDDING_PROPERTIES);
            if (data.status) {
                setBids([...data.data])
                setTotalCount(data.totalCount);
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [type, search])

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        {label}
                    </div>
                </div>
            </div>
            <div className="row">
                {bids.map(item => <div className="col-lg-3 mt-4">
                    <BidProperty title={item.propertyName} onUpdate={()=>{
                        fetchData();
                    }} image={item.image} setId={()=>{
                        setMainId(item._id)
                    }} uploaded={item.updated_at} propertyId={item.propertyId}/>
                </div>)}
            </div>
            <BidPopup id={mainId} />
        </>
    )
}