import React, { useEffect, useState } from 'react'
import Profile_dropdown from '../common/Profile_dropdown'
import Table_profile from '../common/Table_profile'
import { Block, Delete, Eye, Search } from '../common/Icons'
import Search_input from '../common/Search_input'
import { Link } from 'react-router-dom'
import { toaster } from '../apis/commonFunctions'
import { deleteRequest, getRequest, patchRequest } from '../apis/functions'
import APIS from '../apis/routes'
import ConfirmationPopup from '../common/popups/ConfirmationPopup'
import NoDataRow from '../common/NoDataRow'
import Button from '../common/Button'
import { useSelector } from 'react-redux'
import Pagination from '../common/Pagination'

const TableRow = ({ name, email, profile, onBlock, onDeleteChoose, _id, blocked, privillages }) => {
    let privillageString = "";
    const ALL_PRIVILLAGES = useSelector(state => state.privillages);
    privillageString = privillages.map(item => (ALL_PRIVILLAGES.filter(element => element.value === item)[0]?.label ?? "")).join(", ")

    return <tr>
        <td><Table_profile image={profile} name={name} /></td>
        <td style={{ textTransform: "inherit" }}>{email}</td>
        <td>{privillageString}</td>
        <td>
            <div className="actions">
                <Link className=' view' to={"/admin/profile/" + _id}>
                    <Eye />
                </Link>
                <button className=' block' style={{ color: (blocked ? "#0F993E" : "#ff4c27") }} onClick={onBlock}>
                    <Block />
                </button>
                <button className=' delete' data-bs-toggle="modal" onClick={onDeleteChoose} data-bs-target="#decline_modal">
                    <Delete />
                </button>
            </div>
        </td>
    </tr>
}

export default function AdminManagement() {

    const [admins, setAdmins] = useState([])
    const [selectedId, setSelectedId] = useState("");
    const [totalCount, setTotalcount] = useState(0);
    const limit = 5;
    const fetchData = async (skip=0) => {
        try {
            const { data } = await getRequest(APIS.GET_SUBADMINS+"?skip="+skip+"&limit="+limit);
            if (data.status) {
                setAdmins([...data.data])
                setTotalcount(data.totalCount);
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const blockUnblockUser = async (id) => {
        try {
            const { data } = await patchRequest(APIS.BLOCK_ADMIN + id, {});
            if (data.status) {
                toaster(data.message, true);
                fetchData();
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    async function deleteAdmin() {
        try {
            const { data } = await deleteRequest(APIS.DELETE_ADMIN + selectedId);
            if (data.status) {
                toaster(data.message, true);
                fetchData();
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        Sub Admin Management
                    </div>
                </div>
                <div className="col-lg-6">
                    <Search_input placeholder={'Search here'} icon={<Search />} className='search_input' />
                </div>
            </div>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6  mt-4">
                    <div className="create-admin">
                        <Link to="/admin-management/create">Create Admin</Link>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="table-responsive">
                    <table className='table table-lg'>
                        <thead className='table-light'>
                            <tr colspan="4">
                                <th>Name</th>
                                <th>Email address</th>
                                <th>Privillages</th>
                                <th>action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {admins.length > 0 ? admins.map(item => <TableRow name={item.name} email={item.email} profile={item.profile_pic} _id={item._id} blocked={item.isBlocked} privillages={item.privillages} onBlock={() => {
                                blockUnblockUser(item._id)
                            }} onDeleteChoose={() => {
                                setSelectedId(item._id)
                            }} />) : <NoDataRow />}
                        </tbody>
                    </table>
                    <Pagination handlePageClick={(i) => { fetchData(i.selected*limit) }} totalCount={totalCount} limit={limit} />
                </div>
            </div>
            <ConfirmationPopup onYes={deleteAdmin} message="Do you really want to delete this Admin ?" />
        </>
    )
}
