import React from 'react'
import { Pencil } from './Icons'

export default function Edit_button({onClick,...props}) {
    return (
        <>
            <button onClick={onClick} className='edit_btn ms-auto d-flex align-items-center' {...props}><span><Pencil/></span>Edit</button>
        </>
    )
}
