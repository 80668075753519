import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import Sidebar from '../common/Sidebar'
import Header from '../common/Header'

const Protected = () => {
    let TOKEN = localStorage['x_token'];
    if (TOKEN) {
        return (
            <>
                <Sidebar />
                <main className='main' id='main'>
                    <section className='section'>
                        <div className="row m-0">
                            <div className="col-lg-12 col-md-12 ps-0 pe-0 setBorder">
                                <Header />
                                <div className="all_comp">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </>
        )
    } else {
        return <Navigate to="/login" replace />
    }
}

export default Protected