import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    name: "",
    email : "",
    profile_pic : "",
    withdraw_days : 0,
    privillages : [],
    role : "",
    _id : ""
}

export const detailsSlice = createSlice({
    name: 'details',
    initialState: initialState,
    reducers: {
        setDetails: (state, {payload}) => {
            state.name = payload.name
            state.email = payload.email
            state.profile_pic = payload.profile_pic 
            state.withdraw_days = payload.withdraw_days
            state.role = payload.role
            state.privillages = payload.privillages
            state._id = payload._id
        }
    }
})

export const { setDetails } = detailsSlice.actions

export default detailsSlice.reducer;