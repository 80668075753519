import React, { useEffect, useState } from 'react'
import Editor from '../common/Editor'
import Button from '../common/Button';
import APIS from '../apis/routes'
import { getRequest, patchRequest } from '../apis/functions';
import { toaster } from '../apis/commonFunctions'

export default function PrivacyPolicy() {
    const [value, setValue] = useState('');

    const fetchData = async () => {
        try {
            const { data } = await getRequest(APIS.GET_PRIVACY_POLICY);
            setValue(data.data.content)
        } catch (err) {
            toaster(err.message, false);
        }
    }

    function updateContent() {
        let obj = { content: value, type: 2 }
        patchRequest(APIS.UPDATE_CONTENT, obj).then((res) => {
            if (res.data.status) {
                toaster(res.data.message, true);
            } else {
                toaster(res.data.message, false);
            }
        }).catch(err => {
            toaster(err.message, false);
        });
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-12">
                    <div className="pagetitle">
                        <button className='d-flex align-items-center'>Privacy Policy</button>
                    </div>
                </div>
                {/* <div className="col-lg-6 text-end">
                    <button className='blank_btn pe-0'>
                        <Filter />
                    </button>
                </div> */}
            </div>
            <div className="row left_col mt-5">
                <Editor onChange={(value) => { setValue(value) }} value={value} />
                <div className='mt-5'>
                    <Button text="Save Data" type="button" onClick={updateContent}/>
                </div>
            </div>
        </>
    )
}
