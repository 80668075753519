import React, { useRef, useState } from 'react'
import Input from './Input'
import Button from './Button';
import Image from './Image';
import { useSelector } from 'react-redux';

const EditProfile = ({ name, email, joining_date, withdraw_days, privillages, profile_pic, onUpdateProfile, commision, loader = false }) => {

    const ALL_PRIVILLAGES = useSelector(state => state.privillages)
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(profile_pic ? profile_pic : '/user.jpg')
    const inputRef = useRef();
    function onFileChoose(e) {
        setFile(e.target.files[0]);
        if (e.target.files[0]) {
            setPreview(window.URL.createObjectURL(e.target.files[0]))
        }
    }

    const [details, setData] = useState({
        name,
        email,
        withdraw_days,
        privillages: privillages ?? [],
        commision_percentage : commision ?? {}
    })

    function onEdit() {
        inputRef.current.click();
    }

    function handleChanges(e) {
        if (e.target.name === "withdraw_days") {
            if (!isNaN(e.target.value)) {
                setData({
                    ...details,
                    [e.target.name]: e.target.value
                })
            }
        } else {
            if (e.target.name === "rent" || e.target.name === "auction" || e.target.name === "rental") {
                setData({
                    ...details,
                    commision_percentage: { ...details.commision_percentage, [e.target.name]: e.target.value }
                })
                return
            }
            setData({
                ...details,
                [e.target.name]: e.target.value
            })
        }
    }

    function handleSelection(e) {
        if (e.target.checked) {
            setData({
                ...details,
                privillages: [...details.privillages.filter(item => item !== e.target.value), e.target.value]
            })
        } else {
            setData({
                ...details,
                privillages: [...details.privillages.filter(item => item !== e.target.value)]
            })
        }

    }

    return (
        <div className={"row " + (joining_date ? "border-bottom" : "")}>
            <div className="col-lg-2 col-md-2">
                <div className="profile_image mt-4">
                    <Image src={preview} alt="" className="rounded-circle img-fluid" onClick={onEdit} />
                    <input type='file' multiple="false" onChange={onFileChoose} className='opacity-0' ref={inputRef} />
                </div>
            </div>
            <div className="col-lg-10 col-md-10">
                <ul className="profile_list p-0 edit_profile">
                    <li>
                        <div className="detail_wrapper">
                            <Input type="text" placeholder='Enter the name' onChange={handleChanges} value={details.name} name="name" />
                        </div>
                    </li>
                    <li>
                        <div className="detail_wrapper">
                            <Input type="email" disabled placeholder='Enter the email' name="email" value={details.email} onChange={handleChanges} />
                        </div>
                    </li>
                    {withdraw_days && <li >
                        <div className="detail_wrapper">
                            <Input type="text" placeholder='Enter withdraw days' name="withdraw_days" value={details.withdraw_days} onChange={handleChanges} maxLength="2" />
                        </div>
                    </li>}
                    {privillages && <li >
                        <h5 className=' mt-3'>Grant Privillages</h5>
                        <div className="detail_wrapper checkboxes">
                            {ALL_PRIVILLAGES.map((item, i) => <div class=" form-check-inline">
                                <input class="styled-checkbox" type="checkbox" id={"inlineCheckbox" + i} value={item.value} name='privillages' checked={details.privillages.includes(item.value)} onChange={handleSelection} />
                                <label class="form-check-label" for={"inlineCheckbox" + i}>{item.label}</label>
                            </div>)}
                        </div>
                    </li>}
                    {commision.rent && <li >
                        <div className="detail_wrapper">
                            <Input type="text" placeholder='Enter Rent commision charges in percentage %' name="rent" value={details?.commision_percentage?.rent} onChange={handleChanges} maxLength="2" />
                        </div>
                    </li>}
                    {commision.auction && <li >
                        <div className="detail_wrapper">
                            <Input type="text" placeholder='Enter Auction commision charges in percentage %' name="auction" value={details?.commision_percentage?.auction} onChange={handleChanges} maxLength="2" />
                        </div>
                    </li>}
                    {commision.rental && <li >
                        <div className="detail_wrapper">
                            <Input type="text" placeholder='Enter Rental Auction commision charges in percentage %' name="rental" value={details?.commision_percentage?.rental} onChange={handleChanges} maxLength="2" />
                        </div>
                    </li>}
                    <li >
                        <div className="detail_wrapper left_col">
                            <Button loader={loader} type="button" text="Update Profile" onClick={() => {
                                onUpdateProfile(details, file);
                            }} />
                        </div>
                    </li>
                </ul>

            </div>
        </div>
    )
}

export default EditProfile