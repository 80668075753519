import React, { useEffect, useState } from 'react'
import Profile_dropdown from '../common/Profile_dropdown'
import Table_profile from '../common/Table_profile'
import { Block, Delete, Eye, Search } from '../common/Icons'
import Search_input from '../common/Search_input'
import { Link, useParams } from 'react-router-dom'
import { toaster } from '../apis/commonFunctions'
import { getRequest } from '../apis/functions'
import APIS from '../apis/routes'
import ConfirmationPopup from '../common/popups/ConfirmationPopup'
import NoDataRow from '../common/NoDataRow'

const TableRow = ({name,amount,profile,description,type,_id,blocked}) => {
    return <tr>
        <td><Table_profile image={profile} name={name} /></td>
        <td style={{textTransform : "inherit"}} className={amount < 0 ? 'color-red' : amount > 0 ? 'color-green' : ''}>${Math.abs(amount)}</td>
        <td>{description}</td>
        <td className='text-capitalize'>{type}</td>
    </tr>
}

export default function Payments() {
    const [payments,setPayments] = useState([]);

    const fetchData = async () => {
        try {
            const { data } = await getRequest(APIS.GET_PAYMENTS);
            if (data.status) {
                console.log(data.data," this is the all about data  ")
                setPayments([...data.data])
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(()=>{
        fetchData();
    },[])

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        Payments
                    </div>
                </div>
                <div className="col-lg-6">
                    <Search_input filter={false} placeholder={'Search here'} icon={<Search />} className='search_input' />
                </div>
            </div>
            <div className="row mt-5">
                <div className="table-responsive">
                    <table className='table table-lg'>
                        <thead className='table-light'>
                            <tr colspan="4">
                                <th>Name</th>
                                <th>Amount</th>
                                <th>Description</th>
                                <th>action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {payments.length > 0 ? payments.map(item => <TableRow name={item.user} amount={item.amount} description={item.description} profile={item.profile_pic} _id={item._id} blocked={item.isBlocked} type={item.type} />) : <NoDataRow />}
                        </tbody>
                    </table>
                </div>
            </div>
            <ConfirmationPopup/>
        </>
    )
}
