import React, { useState } from 'react'
import Input from '../common/Input'
import Button from '../common/Button'
import { postRequest, setToken } from '../apis/functions'
import APIS from '../apis/routes'
import { Link, useNavigate } from 'react-router-dom'
import { toaster } from '../apis/commonFunctions'

export default function ForgotPassword() {
    const router = useNavigate();
    const [loader, setLoader] = useState(false);
    const [login, setLogin] = useState({
        email: "admin@munzul.com",
        login_type: "NORMAL",
        device_token: "fvfvdfvdf",
        device_type: "dklnfdsnfs",
        email_recover: ""
    });

    function handleChanges(e) {
        setLogin({
            ...login,
            [e.target.name]: e.target.value.trim()
        })
    }

    async function submitForm(event) {
        try {
            setLoader(true);
            const { data: { status, code, message, token, info } } = await postRequest(APIS.LOGIN, login)
            setLoader(false);
            if (status) {
                // localStorage.setItem('x_token', token)
                setToken(token)
                localStorage.setItem('name', info.name)
                localStorage.setItem('email', info.email)
                localStorage.setItem('profile_pic', info.profile_pic)
                localStorage.setItem('withdraw_days', info.withdraw_days)
                localStorage.setItem('uid', info.uid)
                router('/')
            } else {
                toaster(message, false)
            }
        } catch (err) {
            toaster(err.message, false)
        }
    };

    return (
        <>
            <div className="container">
                <div className="row align-items-center h-100vh">
                    <div className="col-lg-5 col-md-5 mobile_hide">
                        <div className="center_img text-center">
                            <img src="/login-img.png" alt="" className='img-fluid' />
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-5">
                        <div className="login_area">
                            <div className="logo"><img src="/logo.png" alt="logo" className='img-fluid' /></div>
                            <p className='mt-3'>Reset your Password</p>
                            <div className="mt-5">
                                <div className="group">
                                    <Input className={'mt-2'} type={'text'} onChange={handleChanges} autoComplete={'off'} placeholder={'abc@example.com'} value={login.email} name="email" label={'Email'} />
                                </div>
                                <div className="text-end forget">
                                    <Link to='/login'>Login</Link>
                                </div>
                                <div className="site_btn mt-5">
                                    <Button loader={loader} type="submit" onClick={submitForm} text={'Send Verification Link'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
