import React, { useState } from 'react'
import { toaster } from '../apis/commonFunctions'
import { postRequest } from '../apis/functions'
import APIS from '../apis/routes'
import AddAdmin from '../common/AddAdmin'

export default function CreateAdmin() {
    const [userDetails, setData] = useState({
        name: "",
        email: "",
        profile_pic: "",
        privillages: []
    })

    async function createAdmin(values) {
        try {
            const { data } = await postRequest(APIS.CREATE_ADMIN, values);
            if (data.status) {
                toaster(data.message, true);
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        <button className='d-flex align-items-center'>Create Admin</button>
                    </div>
                </div>
            </div>
            <div className="outer_div mt-4">
                <AddAdmin privillages={userDetails.privillages} name={userDetails.name} email={userDetails.email} profile_pic={userDetails.profile_pic} onFormSubmit={(data) => {
                    createAdmin(data)
                }} />
            </div>
        </>
    )
}
