import React, { useRef, useState } from 'react'
import Input from './Input'
import Button from './Button';
import { toaster } from '../apis/commonFunctions';
import Image from './Image';
import { useSelector } from 'react-redux';

const AddAdmin = ({ name, email, profile_pic, privillages, onFormSubmit }) => {

    const [file, setFile] = useState(null);
    const ALL_PRIVILLAGES = useSelector(state => state.privillages)
    const [preview, setPreview] = useState(profile_pic ? profile_pic : '/user.jpg')
    const inputRef = useRef();
    function onFileChoose(e) {
        setFile(e.target.files[0]);
        if (e.target.files[0]) {
            setPreview(window.URL.createObjectURL(e.target.files[0]))
        }
    }

    const [details, setData] = useState({
        name,
        email,
        privillages
    })

    function onEdit() {
        inputRef.current.click();
    }

    function handleSelection(e) {
        if (e.target.checked) {
            setData({
                ...details,
                privillages: [...details.privillages.filter(item => item !== e.target.value), e.target.value]
            })
        } else {
            setData({
                ...details,
                privillages: [...details.privillages.filter(item => item !== e.target.value)]
            })
        }

    }

    function handleChanges(e) {
        if (e.target.name === "withdraw_days") {
            if (!isNaN(e.target.value)) {
                setData({
                    ...details,
                    [e.target.name]: e.target.value
                })
            }
        } else {
            setData({
                ...details,
                [e.target.name]: e.target.value
            })
        }
    }

    const formSubmit = async () => {
        try {
            let privillageString = "";
            if (details.privillages.length > 0) {
                privillageString = details.privillages.map(item => (ALL_PRIVILLAGES.filter(element => element.value === item)[0]?.label ?? "")).join(", ")
            } else {
                privillageString = "No Privillage Grant"
            }
            let formData = new FormData();
            formData.append('name', details.name);
            formData.append('email', details.email);
            formData.append('privillagemessage', privillageString)
            for (let item of details.privillages) {
                formData.append('privillages', item);
            }
            if (file) {
                formData.append('profile_pic', file);
            }
            onFormSubmit(formData);
        } catch (err) {
            toaster(err.message, false);
        }
    }

    return (
        <div className="row">
            <div className="col-lg-2 col-md-2">
                <div className="profile_image mt-4">
                    <Image src={preview} alt="" className="rounded-circle img-fluid" onClick={onEdit} />
                    <input type='file' multiple="false" onChange={onFileChoose} className='opacity-0' ref={inputRef} />
                </div>
            </div>
            <div className="col-lg-10 col-md-10">
                <ul className="profile_list p-0 edit_profile">
                    <li>
                        <div className="detail_wrapper">
                            <Input type="text" placeholder='Enter the name' onChange={handleChanges} value={details.name} name="name" />
                        </div>
                    </li>
                    <li>
                        <div className="detail_wrapper">
                            <Input type="email" placeholder='Enter the email' name="email" value={details.email} onChange={handleChanges} />
                        </div>
                    </li>
                    <li >
                        <h5 className=' mt-3'>Grant Privillages</h5>
                        <div className="detail_wrapper checkboxes">
                            {ALL_PRIVILLAGES.map((item, i) => <div class="form-check form-check-inline">
                                <input class="styled-checkbox" type="checkbox" id={"inlineCheckbox" + i} value={item.value} name='privillages' onChange={handleSelection} />
                                <label class="form-check-label" for={"inlineCheckbox" + i}>{item.label}</label>
                            </div>)}
                        </div>
                    </li>
                    <li >
                        <div className="detail_wrapper left_col">
                            <Button type="button" text="Submit" onClick={() => {
                                formSubmit();
                            }} />
                        </div>
                    </li>
                </ul>

            </div>
        </div>
    )
}

export default AddAdmin