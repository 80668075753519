import React, { useEffect, useState } from 'react'
import EditButton from '../common/Edit_button'
import { Back } from '../common/Icons'
import PropertyCard from '../common/PropertyCard'
import DetailsViewer from '../common/DetailsViewer'
import { useNavigate, useParams } from 'react-router-dom'
import { toaster } from '../apis/commonFunctions'
import { getRequest, patchRequest } from '../apis/functions'
import APIS from '../apis/routes'
import NotFound from '../common/NotFound'
import EditProfile from '../common/EditProfile'

export default function Customer_details() {
    const router = useNavigate();
    const { id } = useParams();
    const [toggle, setToggle] = useState(false);
    const [details, setDetails] = useState({
        name: "",
        email: "",
        joined_on: 0,
        profile_pic: "",
        properties: []
    })

    useEffect(() => {
        (async () => {
            try {
                const { data } = await getRequest(APIS.GET_DETAILS + id);
                if (data.status) {
                    setDetails({
                        ...data.data
                    })
                } else {
                    toaster(data.message, false);
                }
            } catch (err) {
                toaster(err.message, false);
            }
        })()
    }, [id])

    async function updateProfile(updated_details, file) {
        try {
            let formData = new FormData();
            formData.append('name', updated_details.name);
            formData.append('email', updated_details.email);
            if (file) {
                formData.append('profile_pic', file);
            }
            const { data } = await patchRequest(APIS.GET_DETAILS + id, formData);
            if (data.status) {
                toaster(data.message, true);
                router(-1);
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        <button className='d-flex align-items-center' onClick={() => {
                            router(-1)
                        }}><span><Back /></span>Customer details</button>
                    </div>
                </div>
                <div className="col-lg-6 text-end">
                    <EditButton onClick={(e) => {
                        setToggle(!toggle)
                    }} />
                </div>
            </div>
            <div className="outer_div mt-4">
                {!toggle ?
                    <DetailsViewer joining_date={details.joined_on} name={details.name} email={details.email} profile_pic={details.profile_pic} />
                    : <EditProfile name={details.name} email={details.email} profile_pic={details.profile_pic} onUpdateProfile={updateProfile} />}<div className="card_botom">
                    <div className="sub_title mt-4">Properties</div>
                    <div className="row">
                        {details.properties.length > 0 ? details.properties.map(item => <div className="col-lg-4 mt-4">
                            <PropertyCard id={item._id} image={item.image} address={item.address} title={item.title} bth={item.no_of_bathrooms} bds={item.no_of_bedrooms} area={item.area_sqft} ClassName={'cancel'} />
                        </div>) : <NotFound />}
                    </div>
                </div>
            </div>
        </>
    )
}
