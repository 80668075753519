import React from 'react'
import { Filter } from './Icons'

export default function Search_input({ filter,handleSearch, handleFilter, label, htmlFor, name, value, type, placeholder, required, icon, onChange, className,search, accept, loader, autoComplete, ...props }) {
    return (
        <>
            <div className="search_box d-flex align-items-center justify-content-end">
                <div className={"form-group position-relative " + className}>
                    <input type={type} value={search} name={name}
                        placeholder={`${placeholder} ${required ? '*' : ''}`} onChange={handleSearch} {...props} htmlFor />
                    <span className='search_icons'>
                        {icon}
                    </span>
                </div>
                {filter && <button className='blank_btn pe-0'>
                    <Filter />
                </button>}
            </div>

        </>
    )
}
