const BASE_URL = process.env.REACT_APP_BASE_URL

const routes = {
    LOGIN: "/admin/login",
    CHANGE_PASSWORD: "/admin/change-password",
    GET_STATS: "/admin/get-stats",
    GET_DATA: "/admin/get-data",
    ALL_PROPERTIES: "/admin/getAllProperty",
    FORGOT_PASSWORD: "/admin/recoverAccount",
    VERIFY_OTP: "/admin/verifyOtp",
    VERIFY_CHANGE_PASSWORD: "/admin/verify-change-password",
    BLOCK_USER: "/admin/blockUser/",
    GET_DOCUMENTS: "/admin/getdocuments",
    UPDATE_PROFILE: "/admin/update-profile",
    UPDATE_SUBADMIN_PROFILE: "/admin/profile/",
    GET_REASONS: "/admin/getReason/",
    HIDE_SHOW_PROPERTY: "/admin/hideProperty/",
    GET_PAYMENTS: "/admin/get-payments",
    ALL_USERS: "/admin/getAllUsers",
    GENERATE_TOKEN: "/admin/generate-user-token/",
    DOWNLOAD_DOCUMENTS: "/property/downloadDocuments/",
    GET_PRIVACY_POLICY: "/admin/getPrivacy",
    GET_TERMS_AND_CONDITIONS: "/admin/getTerms",
    GET_REPORTED_PROPERTY: "/admin/getAllReport",
    UPDATE_CONTENT: "/page/updatePage",
    GET_DETAILS: "/admin/users/",
    GET_REFRESH_TOKEN: "/admin/get-refresh-token",
    GET_PROFILE: "/admin/profile",
    GET_SUBADMINS: "/admin/list",
    CREATE_ADMIN: "/admin/create",
    BLOCK_ADMIN: "/admin/block/",
    DELETE_ADMIN: "/admin/",
    CPM_PACKAGES: "/admin/cpm-packages",
    DELETE_DOCUMENT: "/admin/document/",
    SUSPEND_PROPERTY: "/admin/suspend-property/",
    DELETE_PROPERTY: "/admin/property/",
    BIDDING_PROPERTIES: "/admin/bidding-properties",
    GET_BIDS: "/admin/bids/",
    DECLINE_DELETE_BID: "/admin/bid/",
    GET_BADGES: "/admin/badges",

}

export default routes;
export { BASE_URL }