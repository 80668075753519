import React from 'react'
import Button from '../common/Button'
import Image from './Image'

export default function ReportedPropertyCard({ title,image,_id,count,ClassName,onClick,...props }) {
    return (
        <>
            <div className="card_outer">
                <div className="card_column">
                    <div className="left_col property-card">
                        <Image placeholder="/image-not-found.png" alt='property' src={image} className='img-fluid'/>
                        <div className="name">{title}</div>
                        <div className="small_heading">Reported : {count} Times</div>
                        <Button text="View Reasons" type="button" data-bs-toggle="modal" data-bs-target="#view_reasons" onClick={onClick}/>
                    </div>
                    {/* <div className="right_col">
                        <div className=" service_name">{Service_price}</div>
                    </div> */}
                </div>
            </div>
        </>
    )
}
