import React, { useEffect, useState } from 'react'
import Profile_dropdown from '../../common/Profile_dropdown'
import Table_profile from '../../common/Table_profile'
import { Block, Delete, Eye, Search } from '../../common/Icons'
import Search_input from '../../common/Search_input'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { toaster } from '../../apis/commonFunctions'
import { getRequest, patchRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import ConfirmationPopup from '../../common/popups/ConfirmationPopup'
import NoDataRow from '../../common/NoDataRow'
import Pagination from '../../common/Pagination'
import * as moment from 'moment'

const TableRow = ({ impressions, price,updated, _id, blocked, onBlock }) => {
    return <tr>
        <td>{impressions}</td>
        <td style={{ textTransform: "inherit" }}>${price}</td>
        <td>{moment(updated).format("DD MMM, YYYY")}</td>
        <td>
            <div className="actions">
                <Link className=' view' to={"/user-management/details/" + _id}>
                    <Eye />
                </Link>
                <button className=' block' style={{ color: (blocked ? "#0F993E" : "#ff4c27") }} onClick={onBlock}>
                    <Block />
                </button>
                <button className=' delete' data-bs-toggle="modal" data-bs-target="#decline_modal">
                    <Delete />
                </button>
            </div>
        </td>
    </tr>
}

export default function CpmPackages() {
    const [label, setLabel] = useState('CPM Packages');
    const [users, setUsers] = useState([]);
    const [totalCount,setTotalCount] = useState(0);
    const limit = 5;

    const fetchData = async (skip=0) => {
        try {
            const { data } = await getRequest(APIS.CPM_PACKAGES+"?skip="+skip+"&limit="+limit);
            if (data.status) {
                setUsers([...data.data])
                setTotalCount(data.totalCount);
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    const blockUnblockUser = async (id) => {
        try {
            const { data } = await patchRequest(APIS.BLOCK_USER + id, {});
            if (data.status) {
                toaster(data.message, true);
                fetchData();
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        {label}
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="table-responsive">
                    <table className='table table-lg'>
                        <thead className='table-light'>
                            <tr colspan="4">
                                <th>Impressions</th>
                                <th>Price</th>
                                <th>Updated On</th>
                                <th>action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length > 0 ? users.map(item => <TableRow impressions={item.impressions} price={item.price} _id={item._id} updated={item.updated_at}  onBlock={() => {
                                blockUnblockUser(item._id)
                            }} />) : <NoDataRow />}
                        </tbody>
                    </table>
                    <Pagination handlePageClick={(i) => { fetchData(i.selected*limit) }} totalCount={totalCount} limit={limit} />
                </div>
            </div>
            <ConfirmationPopup message="Do you want to delete this user ?" />
        </>
    )
}
