import * as moment from 'moment'
import React from 'react'
import Image from './Image'
import { useSelector } from 'react-redux';

const DetailsViewer = ({ name, email, joining_date, privillages, withdraw_days, profile_pic, commision }) => {
    let privillageString = "";
    const ALL_PRIVILLAGES = useSelector(state => state.privillages);
    if (privillages && privillages.length > 0) {
        privillageString = privillages.map(item => (ALL_PRIVILLAGES.filter(element => element.value === item)[0]?.label ?? "")).join("\n")
    }else{
        privillageString = "No Privillage Grant"
    }

    return (
        <div className={"row " + (joining_date ? "border-bottom" : "")}>
            <div className="col-lg-2 col-md-2">
                <div className="profile_image">
                    <Image src={profile_pic !== "" ? profile_pic : "/user.jpg"} alt="" className="rounded-circle img-fluid" />
                </div>
            </div>
            <div className="col-lg-10 col-md-10">
                <ul className="profile_list p-0">
                    <li >
                        <div className="detail_wrapper">
                            <div className="small_heading">Full Name</div>
                            <div className="name mt-2">{name}</div>
                        </div>
                    </li>
                    <li >
                        <div className="detail_wrapper">
                            <div className="small_heading">Email Address</div>
                            <div className="name mt-2 text-lowercase">{email}</div>
                        </div>
                    </li>
                    {joining_date && <li >
                        <div className="detail_wrapper">
                            <div className="small_heading">joining date</div>
                            <div className="name mt-2">{moment(joining_date).format("DD MMM, YYYY")}</div>
                        </div>
                    </li>}
                    {withdraw_days && <li >
                        <div className="detail_wrapper">
                            <div className="small_heading">withdraw days</div>
                            <div className="name mt-2">{withdraw_days} Days</div>
                        </div>
                    </li>}
                    {privillages && <li >
                        <div className="detail_wrapper">
                            <div className="small_heading">Privillages</div>
                            <div className="name mt-2 broken-line">{privillageString}</div>
                        </div>
                    </li>}
                    {commision && <li >
                        <div className="detail_wrapper">
                            <div className="small_heading">Commision</div>
                            <div className="name mt-2 broken-line">Rent : {commision.rent}% &nbsp;&nbsp;&nbsp;&nbsp; Auction : {commision.auction}% &nbsp;&nbsp;&nbsp;&nbsp; Rental Auction : {commision.rental}%</div>
                        </div>
                    </li>}
                </ul>

            </div>
        </div>
    )
}

export default DetailsViewer