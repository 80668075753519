import React, { useEffect, useState } from 'react'
import { Cancel } from '../Icons'
import Table_profile from '../Table_profile'
import { deleteRequest, getRequest, patchRequest } from '../../apis/functions'
import APIS from '../../apis/routes'
import { toaster } from '../../apis/commonFunctions'
import * as moment from 'moment'
import NoDataRow from '../NoDataRow'
import Button from '../Button'

const TableRow = ({ name, price, profile, status, reported_on, onDecline, onDelete }) => {
    return <tr>
        <td><Table_profile image={profile} name={name} /></td>
        <td style={{ textTransform: "inherit" }}>${price}</td>
        <td>{moment(reported_on).format("DD MMM, YYYY")}</td>
        <td className='d-flex gap-3'>{status === "ACCEPTED" ? <><Button text="Accepted" disabled className="p-2" /></> : status === "PENDING" ? <>
            <Button text="Decline" onClick={onDecline} className="p-2" style={{ background: "#FA2D11", color: "white" }} /><Button text="Delete" onClick={onDelete} className="p-2" style={{ background: "#FA2D11", color: "white" }} />
        </> : <Button text="Delete" className="p-2" onClick={onDelete} style={{ background: "#FA2D11", color: "white" }} />}</td>
    </tr>
}

const BidPopup = ({ id }) => {
    const [bids, setBids] = useState([]);

    const fetchData = async () => {
        try {
            const { data } = await getRequest(APIS.GET_BIDS + id);
            if (data.status) {
                setBids([...data.data])
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id])

    const declineBid = async (id) => {
        try{
            const {data} = await patchRequest(APIS.DECLINE_DELETE_BID+id);
            if(data.status){
                toaster(data.message,true);
                fetchData();
            }else{
                toaster(data.message,false);
            }
        }catch(err){
            toaster(err.message,false);
        }
    }

    const deleteBid = async (id) => {
        try{
            const {data} = await deleteRequest(APIS.DECLINE_DELETE_BID+id);
            if(data.status){
                toaster(data.message,true);
                fetchData();
            }else{
                toaster(data.message,false);
            }
        }catch(err){
            toaster(err.message,false);
        }
    }

    return (
        <div class="modal fade" id="view_bids" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal modal-dialog-centered">
                <div class="modal-content">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    <div class="modal-body text-center">
                        <div className="modal_icon">
                            {/* <Cancel /> */}
                        </div>
                        <div className="table-responsive">
                            <table className='table table-lg'>
                                <thead className='table-light'>
                                    <tr colspan="4">
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>Bid on</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bids.length > 0 ? bids.map(item => <TableRow name={item.details.name} price={item.amount.toLocaleString()} status={item.status} profile={item.details.profile_pic} reported_on={item.updated_at} onDecline={()=>{
                                        declineBid(item._id)
                                    }} onDelete={()=>{
                                        deleteBid(item._id)
                                    }} />) : <NoDataRow />}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BidPopup