import React, { useEffect, useState } from 'react'
import Card from '../common/Card'
import { getRequest } from '../apis/functions'
import APIS from '../apis/routes'
import { toaster } from '../apis/commonFunctions'

export default function Dashboard() {
    const [data, setData] = useState([{
        name: 'Buyers',
        count: 6
    }, {
        name: 'Sellers',
        count: 5
    }, {
        name: 'Agents',
        count: 3
    }, {
        name: 'Sale Properties',
        count: 18
    }, {
        name: 'Rent Properties',
        count: 6
    }, {
        name: 'Sale Auction Properties',
        count: 10
    }, {
        name: 'Rental Auction Properties',
        count: 2
    }, {
        name: 'Revenue',
        count: 500
    }]);
    const fetchData = async () => {
        try {
            const { data } = await getRequest(APIS.GET_STATS);
            if (data.status) {
                setData([...data.data])
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <div className="pagetitle">
                Dashboard
            </div>
            <div className="row mt-5">
                {data.map(item => <Card Provider={item.name} number={item.count} graph={''} />)}
            </div>
        </>
    )
}
