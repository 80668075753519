import React, { useState } from 'react'
import Button from '../common/Button'
import * as moment from 'moment'
import { deleteRequest, getRequest } from '../apis/functions'
import APIS, { BASE_URL } from '../apis/routes'
import { toaster } from '../apis/commonFunctions'
import Image from './Image'
import { Delete } from './Icons'

export default function Document({ title,image,uploaded,_id,className,propertyId,onUpdate,...props }) {

    async function downloadDocuments() {
        try {
            setLoader(true)
            const { data } = await getRequest(APIS.DOWNLOAD_DOCUMENTS+propertyId)
            setLoader(false)
            if (data.status) {
                window.open(BASE_URL+"/" + data.link)
                toaster(data.message,true)
            } else {
                toaster(data.message,false)
            }
        } catch (err) {
            setLoader(false);
            toaster(err.message,false)
        }
    }

    async function deleteDocument(){
        try {
            const { data } = await deleteRequest(APIS.DELETE_DOCUMENT+propertyId)
            if (data.status) {
                toaster(data.message,true)
            } else {
                toaster(data.message,false)
            }
        } catch (err) {
            setLoader(false);
            toaster(err.message,false)
        }
    }

    const [loader,setLoader] = useState(false);

    return (
        <>
            <div className="card_outer">
                <div className="card_column">
                    <div className="left_col">
                        <Button text={<Delete />} className="delete-btn" onClick={deleteDocument}/>
                        <Image placeholder="/image-not-found.png" alt='property' src={image} className='img-fluid'/>
                        <div className="name">{title}</div>
                        <div className="small_heading">Uploaded on {moment(uploaded).format('DD MMM, YYYY')}</div>
                        <Button loader={loader} text="Download Documents" type="button" onClick={downloadDocuments}/>
                    </div>
                </div>
            </div>
        </>
    )
}
