import React from 'react'

const Home = () => {
    return (
        <>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.6999 4.68373L10.8999 1.3254C9.59154 0.408729 7.5832 0.458729 6.32487 1.43373L2.14987 4.69206C1.31654 5.34206 0.658203 6.6754 0.658203 7.7254V13.4754C0.658203 15.6004 2.3832 17.3337 4.5082 17.3337H13.4915C15.6165 17.3337 17.3415 15.6087 17.3415 13.4837V7.83373C17.3415 6.70873 16.6165 5.3254 15.6999 4.68373ZM9.62487 14.0004C9.62487 14.3421 9.34154 14.6254 8.99987 14.6254C8.6582 14.6254 8.37487 14.3421 8.37487 14.0004V11.5004C8.37487 11.1587 8.6582 10.8754 8.99987 10.8754C9.34154 10.8754 9.62487 11.1587 9.62487 11.5004V14.0004Z" fill="currentColor" />
            </svg>
        </>
    )
}
const User = () => {
    return (
        <>
            <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.64 10.9344C10.2411 10.9344 13.28 11.5195 13.28 13.7771C13.28 16.0356 10.2211 16.6 6.64 16.6C3.03977 16.6 0 16.0148 0 13.7572C0 11.4988 3.05887 10.9344 6.64 10.9344ZM6.64 0C9.07946 0 11.034 1.95384 11.034 4.39157C11.034 6.82931 9.07946 8.78398 6.64 8.78398C4.20138 8.78398 2.24599 6.82931 2.24599 4.39157C2.24599 1.95384 4.20138 0 6.64 0Z" fill="currentColor" />
            </svg>
        </>
    )
}
const Service = () => {
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1876_15485)">
                    <path d="M10.7597 0.818359C10.9814 0.818359 11.1518 0.99916 11.1518 1.2144V3.98667C11.1518 5.56222 12.439 6.85365 13.999 6.86226C14.6468 6.86226 15.1583 6.87087 15.5504 6.87087L15.6956 6.87023C15.9577 6.86824 16.3105 6.86226 16.6159 6.86226C16.829 6.86226 16.9995 7.03445 16.9995 7.24969V14.1718C16.9995 16.3069 15.2861 18.0375 13.1721 18.0375H6.38675C4.17043 18.0375 2.36328 16.2208 2.36328 13.9824V4.70127C2.36328 2.5661 4.08519 0.818359 6.20774 0.818359H10.7597ZM11.6718 11.9247H7.0346C6.6851 11.9247 6.39528 12.2088 6.39528 12.5618C6.39528 12.9148 6.6851 13.2075 7.0346 13.2075H11.6718C12.0213 13.2075 12.3111 12.9148 12.3111 12.5618C12.3111 12.2088 12.0213 11.9247 11.6718 11.9247ZM9.91581 7.6199H7.0346C6.6851 7.6199 6.39528 7.91263 6.39528 8.26562C6.39528 8.61861 6.6851 8.90272 7.0346 8.90272H9.91581C10.2653 8.90272 10.5551 8.61861 10.5551 8.26562C10.5551 7.91263 10.2653 7.6199 9.91581 7.6199ZM12.394 1.59838C12.394 1.22731 12.8398 1.04307 13.0947 1.31083C14.0162 2.27854 15.6264 3.97031 16.5266 4.91564C16.7755 5.17651 16.5931 5.60957 16.2342 5.61043C15.5335 5.61302 14.7075 5.61043 14.1134 5.60441C13.1706 5.60441 12.394 4.82008 12.394 3.86786V1.59838Z" fill="currentColor" />
                </g>
                <defs>
                    <clipPath id="clip0_1876_15485">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}
const Payment = () => {
    return (
        <>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.61 0C3.8458 0 0 3.8458 0 8.61C0 13.3742 3.8458 17.22 8.61 17.22C13.3742 17.22 17.22 13.3742 17.22 8.61C17.22 3.8458 13.3742 0 8.61 0ZM2.296 9.4136C2.296 9.1266 2.5256 8.8396 2.87 8.8396C3.2144 8.8396 3.444 9.1266 3.444 9.4136C3.444 9.9302 3.9606 10.332 4.592 10.332C5.2234 10.332 5.74 9.9302 5.74 9.4136V3.444C5.74 3.0996 5.9696 2.87 6.314 2.87C6.6584 2.87 6.888 3.0996 6.888 3.444V9.4136C6.888 10.5616 5.8548 11.48 4.592 11.48C3.3292 11.48 2.296 10.5616 2.296 9.4136ZM9.758 9.184C10.4468 9.184 10.906 8.8396 10.906 8.4952C10.906 8.1508 11.193 7.9212 11.48 7.9212C11.767 7.9212 12.054 8.1508 12.054 8.4952C12.054 9.5284 11.0208 10.332 9.758 10.332C8.4952 10.332 7.462 9.5284 7.462 8.4952V3.444C7.462 3.0996 7.6916 2.87 8.036 2.87C8.3804 2.87 8.61 3.0996 8.61 3.444V8.4952C8.61 8.8396 9.0692 9.184 9.758 9.184ZM10.906 11.48C10.906 11.767 10.619 12.054 10.332 12.054C10.045 12.054 9.758 11.767 9.758 11.48C9.758 11.193 10.045 10.906 10.332 10.906C10.619 10.906 10.906 11.193 10.906 11.48ZM8.036 11.48C8.036 11.193 8.2656 10.906 8.61 10.906C8.897 10.906 9.184 11.193 9.184 11.48C9.184 11.767 8.897 12.054 8.61 12.054C8.323 12.054 8.036 11.767 8.036 11.48ZM10.332 13.776C10.045 13.776 9.758 13.489 9.758 13.202C9.758 12.915 10.045 12.628 10.332 12.628C11.5374 12.628 13.202 12.2262 13.202 8.61C13.202 8.2656 13.489 8.036 13.776 8.036C14.063 8.036 14.35 8.2656 14.35 8.61C14.35 12.8576 12.1688 13.776 10.332 13.776Z" fill="currentColor" />
            </svg>
        </>
    )
}
const Star = () => {
    return (
        <>
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.4266 10.7119C14.1918 10.9394 14.084 11.2684 14.1375 11.591L14.9431 16.0498C15.0111 16.4277 14.8516 16.8101 14.5353 17.0285C14.2254 17.2551 13.813 17.2823 13.475 17.101L9.46119 15.0076C9.32163 14.9333 9.16666 14.8934 9.00806 14.8889H8.76247C8.67728 14.9016 8.59391 14.9287 8.51778 14.9704L4.50307 17.0738C4.3046 17.1735 4.07985 17.2089 3.85963 17.1735C3.32313 17.072 2.96515 16.5609 3.05306 16.0217L3.85963 11.5629C3.9131 11.2376 3.80525 10.9068 3.57053 10.6757L0.298045 7.50379C0.0243564 7.23826 -0.0708005 6.83951 0.0542628 6.47972C0.175701 6.12085 0.48564 5.85894 0.859924 5.80003L5.36401 5.14662C5.70658 5.11128 6.00745 4.90284 6.16152 4.59471L8.14621 0.525628C8.19334 0.435002 8.25406 0.351627 8.32747 0.280939L8.40903 0.217501C8.45162 0.170376 8.50056 0.131407 8.55494 0.0996881L8.65372 0.0634379L8.80778 0H9.18931C9.53007 0.035344 9.83004 0.239251 9.98682 0.543753L11.9978 4.59471C12.1428 4.89106 12.4246 5.09678 12.75 5.14662L17.2541 5.80003C17.6347 5.85441 17.9528 6.11722 18.0788 6.47972C18.1975 6.84313 18.0951 7.24188 17.816 7.50379L14.4266 10.7119Z" fill="currentColor" />
            </svg>
        </>
    )
}
const Annoucement = () => {
    return (
        <>
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.637 0C14.1204 0 13.776 0.3444 13.776 0.861V1.3776C12.3123 3.1857 10.0737 4.305 7.749 4.305H2.583C1.1193 4.305 0 5.4243 0 6.888V8.61C0 10.0737 1.1193 11.193 2.583 11.193H3.0135L0.9471 16.0146C0.7749 16.4451 0.9471 16.9617 1.3776 17.1339C1.4637 17.22 1.6359 17.22 1.722 17.22H5.166C5.5104 17.22 5.8548 17.0478 5.9409 16.7034L8.2656 11.193C10.4181 11.3652 12.3984 12.3984 13.776 14.0343V14.5509C13.776 15.0675 14.1204 15.4119 14.637 15.4119C15.1536 15.4119 15.498 15.0675 15.498 14.5509V0.7749C15.498 0.4305 15.1536 0 14.637 0ZM2.583 9.471C2.0664 9.471 1.722 9.1266 1.722 8.61V6.888C1.722 6.3714 2.0664 6.027 2.583 6.027H3.444V9.471H2.583ZM4.5633 15.498H3.0135L4.8216 11.193H6.3714L4.5633 15.498Z" fill="currentColor" />
            </svg>
        </>
    )
}
const Disclaimer = () => {
    return (
        <>
            <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.12767 0C9.61197 0 10.0423 0.252323 10.2787 0.674893L18.0857 14.6382C18.3164 15.0507 18.3114 15.5404 18.0722 15.9483C17.8329 16.3564 17.4077 16.6 16.9347 16.6H1.32059C0.847548 16.6 0.422324 16.3564 0.183025 15.9484C-0.0561755 15.5405 -0.0611883 15.0507 0.169608 14.6381L7.97668 0.674942C8.21308 0.252323 8.64337 0 9.12767 0Z" fill="#56535B" />
                <path d="M8.52894 12.1979H9.72814C9.83469 12.1979 9.92188 12.113 9.92468 12.0065L10.1242 4.41366C10.1256 4.36063 10.1055 4.30932 10.0685 4.27133C10.0315 4.23334 9.98076 4.21191 9.92773 4.21191H8.32945C8.27642 4.21191 8.22565 4.23334 8.18864 4.27133C8.15164 4.30932 8.13153 4.36063 8.13296 4.41366L8.3325 12.0065C8.3352 12.113 8.42234 12.1979 8.52894 12.1979ZM9.12854 14.7958C9.67757 14.7958 10.1243 14.3489 10.1243 13.7996C10.1243 13.2506 9.67762 12.8039 9.12854 12.8039C8.57946 12.8039 8.13281 13.2505 8.13281 13.7996C8.13281 14.3489 8.57951 14.7958 9.12854 14.7958Z" fill="currentColor" />
            </svg>
        </>
    )
}
const Eye = () => {
    return (
        <>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.99935 2.33301C8.20585 2.33301 9.34976 2.75159 10.3457 3.52654C11.3416 4.29584 12.1896 5.42149 12.7985 6.82998C12.8441 6.93745 12.8441 7.0619 12.7985 7.16371C11.5806 9.98069 9.41236 11.6663 6.99935 11.6663H6.99366C4.58634 11.6663 2.41805 9.98069 1.20016 7.16371C1.15463 7.0619 1.15463 6.93745 1.20016 6.82998C2.41805 4.01301 4.58634 2.33301 6.99366 2.33301H6.99935ZM6.99935 4.73705C5.74163 4.73705 4.72293 5.74957 4.72293 6.99967C4.72293 8.24412 5.74163 9.25664 6.99935 9.25664C8.25138 9.25664 9.27008 8.24412 9.27008 6.99967C9.27008 5.74957 8.25138 4.73705 6.99935 4.73705ZM7.00003 5.58429C7.77971 5.58429 8.4171 6.21782 8.4171 6.99843C8.4171 7.77338 7.77971 8.40692 7.00003 8.40692C6.21467 8.40692 5.57727 7.77338 5.57727 6.99843C5.57727 6.90227 5.58865 6.81176 5.60572 6.72126H5.63418C6.26589 6.72126 6.77808 6.22348 6.80084 5.60126C6.86345 5.58995 6.93174 5.58429 7.00003 5.58429Z" fill="currentColor" />
            </svg>

        </>
    )
}
const Block = () => {
    return (
        <>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.3736 2.62637C10.3359 1.58869 8.96311 1 7.5 1C4.47807 1 2 3.4732 2 6.5C2 9.52839 4.47231 12 7.5 12C10.5144 12 13 9.53813 13 6.5C13 5.03689 12.4113 3.66404 11.3736 2.62637ZM7.5 10.6895C5.18429 10.6895 3.31055 8.82063 3.31055 6.5C3.31055 5.59763 3.59414 4.74041 4.11621 4.03142L9.96858 9.87732C9.25955 10.4059 8.40237 10.6895 7.5 10.6895ZM10.8773 8.96853L5.03142 3.11621C5.74045 2.59414 6.59763 2.31055 7.5 2.31055C9.81578 2.31055 11.6895 4.17937 11.6895 6.5C11.6895 7.40232 11.4059 8.25955 10.8773 8.96853Z" fill="currentColor" />
            </svg>

        </>
    )

}
const Delete = () => {
    return (
        <>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.0478 5.07365C11.1641 5.07365 11.2696 5.1244 11.353 5.21015C11.4307 5.30173 11.4699 5.41548 11.4585 5.53565C11.4585 5.57531 11.1476 9.50694 10.9701 11.1618C10.8589 12.1774 10.2042 12.794 9.22214 12.8109C8.46704 12.8278 7.72895 12.8337 7.00221 12.8337C6.23065 12.8337 5.47612 12.8278 4.7437 12.8109C3.79457 12.7882 3.13932 12.1605 3.03379 11.1618C2.85112 9.50111 2.5459 5.57531 2.54022 5.53565C2.53455 5.41548 2.57313 5.30173 2.65142 5.21015C2.72857 5.1244 2.83977 5.07365 2.95664 5.07365H11.0478ZM8.20442 1.16699C8.72012 1.16699 9.18079 1.52691 9.31411 2.04023L9.40942 2.46606C9.48657 2.81314 9.78725 3.05872 10.1333 3.05872H11.8342C12.0611 3.05872 12.25 3.24714 12.25 3.48689V3.70855C12.25 3.94246 12.0611 4.13671 11.8342 4.13671H2.16641C1.93892 4.13671 1.75 3.94246 1.75 3.70855V3.48689C1.75 3.24714 1.93892 3.05872 2.16641 3.05872H3.86725C4.21275 3.05872 4.51343 2.81314 4.59115 2.46665L4.68022 2.06882C4.81865 1.52691 5.27421 1.16699 5.79557 1.16699H8.20442Z" fill="currentColor" />
            </svg>

        </>
    )

}
const Search = () => {
    return (
        <>
            <svg className='search_icon' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" /></svg>

        </>
    )

}
const Pencil = () => {
    return (
        <>
            <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5747 15.5127C16.9099 15.5127 17.1819 15.7848 17.1819 16.1199C17.1819 16.4551 16.9099 16.7271 16.5747 16.7271H10.7028C10.3676 16.7271 10.0956 16.4551 10.0956 16.1199C10.0956 15.7848 10.3676 15.5127 10.7028 15.5127H16.5747ZM12.8229 1.92304C12.8634 1.95462 14.2178 3.00708 14.2178 3.00708C14.7101 3.30015 15.0946 3.82396 15.2404 4.4441C15.3853 5.05777 15.28 5.69087 14.9424 6.22601C14.9402 6.22958 14.9379 6.2331 14.9301 6.2436L14.924 6.2517C14.8692 6.32392 14.631 6.62513 13.4287 8.13145C13.4176 8.15119 13.4049 8.16968 13.3911 8.18764C13.3708 8.21413 13.3487 8.23847 13.3252 8.26064C13.2432 8.36389 13.1566 8.47238 13.0655 8.58644L12.881 8.81755C12.5002 9.29438 12.0486 9.85981 11.5128 10.5305L11.2379 10.8746C10.2035 12.1693 8.87873 13.8269 7.1817 15.95C6.8101 16.4131 6.2531 16.6827 5.65481 16.69L2.70872 16.7272H2.70062C2.41969 16.7272 2.1752 16.5345 2.10962 16.2601L1.44657 13.45C1.30975 12.8679 1.44576 12.2672 1.81898 11.8008L9.44531 2.26226C9.44855 2.25902 9.45098 2.25497 9.45422 2.25174C10.2905 1.25189 11.8028 1.10455 12.8229 1.92304ZM8.59502 5.26936L2.76701 12.5594C2.62857 12.7327 2.57756 12.9561 2.62857 13.1707L3.1799 15.5063L5.64024 15.4756C5.87421 15.4731 6.09037 15.3687 6.23367 15.1906C6.9716 14.2673 7.89904 13.1069 8.85257 11.9137L9.18993 11.4915L9.52786 11.0686C10.4221 9.94951 11.3056 8.84376 12.0448 7.91823L8.59502 5.26936ZM10.3893 3.02651L9.3536 4.32053L12.8031 6.96864C13.467 6.137 13.9038 5.58926 13.9442 5.53705C14.077 5.3217 14.1288 5.01729 14.0592 4.72422C13.9879 4.42386 13.8009 4.16884 13.5313 4.00612C13.4738 3.96645 12.1097 2.9075 12.0676 2.87431C11.5543 2.46304 10.8054 2.53428 10.3893 3.02651Z" fill="#009299" />
            </svg>


        </>
    )

}
const Back = () => {
    return (
        <>
            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.375 23.25L11.625 15.5L19.375 7.75" stroke="#56535B" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
            </svg>



        </>
    )

}

const Filter = () => {
    return <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 10C0 4.47715 4.47715 0 10 0H33C38.5228 0 43 4.47715 43 10V33C43 38.5228 38.5228 43 33 43H10C4.47715 43 0 38.5228 0 33V10Z" fill="#009299" />
        <path d="M18.5293 27.123L12.0003 27.123" stroke="white" stroke-width="2" stroke-linecap="round" />
        <path d="M30.2812 27.123L18.5293 27.123" stroke="white" stroke-opacity="0.25" stroke-width="2" stroke-linecap="round" />
        <path d="M21.3467 27C21.3467 25.3431 20.0035 24 18.3467 24C16.6898 24 15.3467 25.3431 15.3467 27C15.3467 28.6569 16.6898 30 18.3467 30C20.0035 30 21.3467 28.6569 21.3467 27Z" fill="white" stroke="white" stroke-width="1.5" />
        <path d="M23.752 14.877L30.281 14.877" stroke="white" stroke-width="2" stroke-linecap="round" />
        <path d="M12 14.877L23.752 14.877" stroke="white" stroke-opacity="0.25" stroke-width="2" stroke-linecap="round" />
        <path d="M20.9346 15C20.9346 16.6569 22.2777 18 23.9346 18C25.5914 18 26.9346 16.6569 26.9346 15C26.9346 13.3431 25.5914 12 23.9346 12C22.2777 12 20.9346 13.3431 20.9346 15Z" fill="white" stroke="white" stroke-width="1.5" />
    </svg>
}

const Cancel = () => {
    return (
        <>
            <svg width="14" height="14" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_2183_35108)">
                    <path d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z" fill="#FF4B26" />
                    <path d="M32.381 12.622C32.0565 12.2975 31.6163 12.1152 31.1574 12.1152C30.6984 12.1152 30.2583 12.2975 29.9337 12.622L22.5035 20.0522L15.0733 12.622C14.7469 12.3067 14.3097 12.1323 13.8559 12.1362C13.4021 12.1401 12.968 12.3222 12.6471 12.6431C12.3262 12.964 12.1442 13.3981 12.1403 13.8519C12.1363 14.3057 12.3108 14.7429 12.626 15.0693L20.0562 22.4995L12.626 29.9297C12.4607 30.0893 12.3289 30.2803 12.2382 30.4915C12.1475 30.7026 12.0997 30.9297 12.0977 31.1596C12.0957 31.3894 12.1395 31.6173 12.2265 31.83C12.3136 32.0427 12.4421 32.2359 12.6046 32.3984C12.7671 32.5609 12.9603 32.6895 13.173 32.7765C13.3857 32.8635 13.6137 32.9073 13.8435 32.9053C14.0733 32.9033 14.3004 32.8556 14.5115 32.7648C14.7227 32.6741 14.9137 32.5423 15.0733 32.377L22.5035 24.9468L29.9337 32.377C30.2602 32.6923 30.6974 32.8667 31.1512 32.8628C31.605 32.8588 32.0391 32.6768 32.36 32.3559C32.6809 32.035 32.8629 31.6009 32.8668 31.1471C32.8708 30.6933 32.6963 30.2561 32.381 29.9297L24.9508 22.4995L32.381 15.0693C32.7055 14.7447 32.8878 14.3046 32.8878 13.8456C32.8878 13.3867 32.7055 12.9465 32.381 12.622Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_2183_35108">
                        <rect width="45" height="45" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

const Next = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.25 17.5625L9.32143 18.75L15.75 11.625L9.32143 4.5L8.25 5.6875L13.6071 11.625L8.25 17.5625Z" fill="#2A2A2A" />
        </svg>

    )
}

const Previous = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" style={{ transform: "rotate(180deg)" }}>
            <path d="M8.25 17.5625L9.32143 18.75L15.75 11.625L9.32143 4.5L8.25 5.6875L13.6071 11.625L8.25 17.5625Z" fill="#2A2A2A" />
        </svg>
    )
}

const Loginas = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="mdi-login" width="14" height="14" viewBox="0 0 24 24"><path d="M10,17V14H3V10H10V7L15,12L10,17M10,2H19A2,2 0 0,1 21,4V20A2,2 0 0,1 19,22H10A2,2 0 0,1 8,20V18H10V20H19V4H10V6H8V4A2,2 0 0,1 10,2Z" />
        </svg>
    )
}

export { Loginas, Previous, Next, Cancel, Filter, Home, User, Service, Payment, Annoucement, Disclaimer, Star, Eye, Block, Delete, Search, Pencil, Back }