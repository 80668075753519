import React from 'react'

const NotFound = () => {
    return (
        <div className='property_not_found'>
            <img src="/noproperty.png" alt='no-property-found' className='img-fluid' />
            <p>No Property added yet</p>
        </div>
    )
}

export default NotFound