import React, { useEffect, useState } from 'react'
import Table_profile from '../common/Table_profile'
import { toaster } from '../apis/commonFunctions'
import { getRequest } from '../apis/functions'
import APIS from '../apis/routes'
import ConfirmationPopup from '../common/popups/ConfirmationPopup'
import NoDataRow from '../common/NoDataRow'
import moment from 'moment'

const TableRow = ({ name, score, date, profile, _id }) => {
    return <tr>
        <td><Table_profile image={profile} name={name} /></td>
        <td style={{ textTransform: "inherit" }} className={score > 500 ? 'color-green' : 'color-red'}>${Math.abs(score)}</td>
        <td>{moment(date).format("DD MMM YYYY")}</td>
        <td>{calculateDaysDifference(date)}</td>
    </tr>
}

function calculateDaysDifference(timestampInMilliseconds) {
    // Current timestamp
    const currentTimestamp = moment();

    // Convert the provided timestamp in milliseconds to a Moment.js object
    const providedTimestamp = moment(timestampInMilliseconds);

    // Calculate the difference in days
    const differenceInDays = providedTimestamp.diff(currentTimestamp, 'days');

    return differenceInDays;
}

export default function Badges() {
    const [payments, setPayments] = useState([]);

    const fetchData = async () => {
        try {
            const { data } = await getRequest(APIS.GET_BADGES);
            if (data.status) {
                setPayments([...data.data])
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        Badges
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="table-responsive">
                    <table className='table table-lg'>
                        <thead className='table-light'>
                            <tr colspan="4">
                                <th>Name</th>
                                <th>Score</th>
                                <th>Expiration Date</th>
                                <th>Days Left</th>
                            </tr>
                        </thead>
                        <tbody>
                            {payments.length > 0 ? payments.map(item => <TableRow name={item.name} score={item.score} date={item.expiration} profile={item.profile_pic} _id={item._id} />) : <NoDataRow />}
                        </tbody>
                    </table>
                </div>
            </div>
            <ConfirmationPopup />
        </>
    )
}
