import React from 'react'
import { Annoucement, Disclaimer, Home, Payment, Service, User, Star } from './Icons'
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function Sidebar() {
    const info = useSelector(state => state.details)

    return (
        <>
            <aside id='sidebar' className='sidebar'>
                <ul className='sidenav' id='sidenav'>

                    {/* Dashboard */}
                        <div className="menu_title">Dashboard</div>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active" to="/" className="nav-link">
                                <span><Home /></span>
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                    {info.role === "SUPERADMIN" && <>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active" to="/payments" className="nav-link">
                                <span><Payment /></span>
                                <span>payments</span>
                            </NavLink>
                        </li></>}

                    {/* Users Management */}
                    {((info.role === "SUPERADMIN") || info.privillages.includes("USERMANAGEMENT")) && <>
                        <div className="menu_title">User Management</div>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active" to="/user-management/users" className="nav-link">
                                <span><User /></span>
                                <span>All Users</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active" to="/user-management/buyers" className="nav-link">
                                <span><User /></span>
                                <span>Buyers</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active" to="/user-management/sellers" className="nav-link">
                                <span><User /></span>
                                <span>Sellers</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active" to="/user-management/agents" className="nav-link">
                                <span><User /></span>
                                <span>Agents</span>
                            </NavLink>
                        </li>
                    </>}

                    {/* Property Management */}
                    {((info.role === "SUPERADMIN") || info.privillages.includes("PROPERTYMANAGEMENT")) && <>
                        <div className="menu_title">Property Management</div>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active" to="/properties?type" className="nav-link">
                                <span><Service /></span>
                                <span>Properties</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active" to="/reported-properties" className="nav-link">
                                <span><Star /></span>
                                <span>Reported Properties</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active" to="/documents" className="nav-link">
                                <span><Annoucement /></span>
                                <span>Documents</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active" to="/badges" className="nav-link">
                                <span><Annoucement /></span>
                                <span>Badges</span>
                            </NavLink>
                        </li>
                    </>}

                    {/* Sub Admin Management */}
                    {info.role === "SUPERADMIN" && <>
                        <div className="menu_title">Sub Admin Management</div>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active" to="/admin-management" className="nav-link">
                                <span><Service /></span>
                                <span>All Admins</span>
                            </NavLink>
                        </li>
                    </>}

                    {/* Bidding Management */}
                    {((info.role === "SUPERADMIN") || info.privillages.includes("BIDMANAGEMENT")) && <>
                        <div className="menu_title">Bidding Management</div>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active" to="/bid-management" className="nav-link">
                                <span><Service /></span>
                                <span>View Bids</span>
                            </NavLink>
                        </li>
                    </>}

                    {/* Sub Admin Management */}
                    {info.role === "SUPERADMIN" && <>
                        <div className="menu_title">Advertisement Management</div>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active" to="/advertisement/impressions" className="nav-link">
                                <span><Annoucement /></span>
                                <span>Impressions</span>
                            </NavLink>
                        </li>
                    </>}

                    {/* Content Management */}
                    {((info.role === "SUPERADMIN") || info.privillages.includes("CONTENTMANAGEMENT")) && <>
                        <div className="menu_title">Content Management</div>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active" to="/privacy-policy" className="nav-link">
                                <span><Service /></span>
                                <span>Privacy Policy</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact activeClassName="active" to="/terms-and-condition" className="nav-link">
                                <span><Service /></span>
                                <span>Terms & conditions</span>
                            </NavLink>
                        </li>
                    </>}
                    {/* <li className="nav-item">
                        <NavLink exact activeClassName="active" to="" className="nav-link">
                            <span><Disclaimer /></span>
                            <span>Disclaimer</span>
                        </NavLink>
                    </li> */}
                </ul>
            </aside>
        </>
    )
}


