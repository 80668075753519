import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { setToken } from '../apis/functions';
import Image from './Image';
import { useSelector } from 'react-redux'

export default function Profile_dropdown() {
    const router = useNavigate();
    const logout = () => {
        localStorage.clear();
        setToken("")
        setTimeout(() => {
            router('/login')
        })
    }

    const info = useSelector(state => state.details)

    return (
        <div className="profile">
            <div class="dropdown">
                <Image src={info.profile_pic} alt="" className='img-fluid rounded-circle sidebar-profile' />
                <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {info.name}
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                    <li><Link class="dropdown-item" to="/admin/profile">Profile</Link></li>
                    <li><Link class="dropdown-item" to="/admin/change-password">Change Password</Link></li>
                    <li><Link class="dropdown-item" onClick={logout}>Logout</Link></li>
                </ul>
            </div>
        </div>
    )
}
