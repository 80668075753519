import React from 'react'
import Profile_dropdown from './Profile_dropdown'
import { useState } from 'react'

export default function Header() {
    const [isClassApplied, setIsClassApplied] = useState('sidetoggle');
    const toggleClass = () => {
        const body = document.body;
        body.classList.toggle('sidetoggle')
        setIsClassApplied((prevState) => !prevState)
    }

    return (
        <>
            <header className='header fixed-top bg-white'>
                <div className="toggle_logo">
                    <div className="toggle_btn"><button onClick={toggleClass}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" /></svg></button></div>
                    <div className="inner_logo">
                        <img src="/logo.png" alt="logo" className='img-fluid' />
                    </div>
                </div>
                <Profile_dropdown />
            </header>


        </>

    )
}
