import React, { useEffect, useState } from 'react'
import { Filter } from '../common/Icons'
import ReportedPropertyCard from '../common/ReportedPropertyCard'
import { getRequest } from '../apis/functions'
import APIS from '../apis/routes'
import { toaster } from '../apis/commonFunctions'
import Reasons from '../common/popups/Reasons'

export default function Properties() {
    const [properties, setProperties] = useState([])
    const [id,setId] = useState("");
    useEffect(() => {
        (async () => {
            try {
                const { data } = await getRequest(APIS.GET_REPORTED_PROPERTY)
                if (data.status) {
                    setProperties([...data.data])
                    // if (searchRef) {
                    //     searchRef.current.focus()
                    // }
                } else {
                    toaster(data.message, false)
                }
            } catch (err) {
                toaster(err.message, false)
            }
        })()
    }, [])

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        <button className='d-flex align-items-center'>Reported Properties</button>
                    </div>
                </div>
                <div className="col-lg-6 text-end">
                    <button className='blank_btn pe-0'>
                        <Filter />
                    </button>
                </div>
            </div>
            <div className="row">
                {properties.map(item => <div className="col-lg-4 mt-4">
                    <ReportedPropertyCard title={item.title} image={item.property_image} count={item.report_count} ClassName={'complete'} onClick={()=>{
                        setId(item.propertyId)
                    }} />
                </div>)}
            </div>
            <Reasons id={id}/>
        </>
    )
}
