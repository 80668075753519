import React, { useEffect, useRef, useState } from 'react'
import { Filter } from '../common/Icons'
import PropertyCard from '../common/PropertyCard'
import { deleteRequest, getRequest } from '../apis/functions';
import APIS from '../apis/routes'
import { toaster } from '../apis/commonFunctions';
import { useSearchParams } from 'react-router-dom';
import ConfirmationPopup from '../common/popups/ConfirmationPopup';

export default function Properties() {
    const [properties, setProperties] = useState([])
    const [searchParams, setSearchParams] = useSearchParams();
    const TYPES = ["ALL", "", "BUY", "RENT", "AUCTION", "RUMA", "RENTAL"]
    const search = searchParams.get('search') ?? ""
    const [selectedId, setSelectedId] = useState("");
    const type = searchParams.get('type') ?? ""
    async function fetchProperties(){
        try {
            const { data } = await getRequest(`${APIS.ALL_PROPERTIES}?search=${search}&type=${type}`)
            if (data.status) {
                setProperties([...data.data])
                // if (searchRef) {
                //     searchRef.current.focus()
                // }
            } else {
                toaster(data.message, false)
            }
        } catch (err) {
            toaster(err.message, false)
        }
    }
    useEffect(() => {
        if (TYPES.includes(type)) {
            fetchProperties();
        } else {
            toaster("Invalid Type", false);
        }
    }, [type, search])

    function handleType(e) {
        setSearchParams({ type: e.target.value })
    }

    async function deleteProperty() {
        try {
            if (selectedId && selectedId !== "") {
                const { data } = await deleteRequest(APIS.DELETE_PROPERTY + selectedId);
                if (data.status) {
                    toaster(data.message, true);
                    fetchProperties();
                } else {
                    toaster(data.message, false);
                }
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        <button className='d-flex align-items-center'>Properties</button>
                    </div>
                </div>
                <div className="col-lg-6 text-end">
                    <div class="dropdown">
                        <button class="dropdown-toggle filter-btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {(type === "" || type === "ALL") ? "All" : type.charAt(0).toUpperCase() + type.substring(1).toLowerCase()} <i class="fa fa-caret-down"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end filter-dropdown">
                            <li><button className='border-bottom' value="" onClick={handleType}>All</button></li>
                            <li><button className='border-bottom' value="BUY" onClick={handleType}>Sale</button></li>
                            <li><button className='border-bottom' value="RENT" onClick={handleType}>Rent</button></li>
                            <li><button className='border-bottom' value="RUMA" onClick={handleType}>Ruma</button></li>
                            <li><button className='border-bottom' value="AUCTION" onClick={handleType}>Auction</button></li>
                            <li><button className='border-bottom' value="RENTAL" onClick={handleType}>Rental Auction</button></li>
                        </ul>
                    </div>
                    {/* <button className='blank_btn pe-0'>
                        <Filter />
                    </button> */}
                </div>
            </div>
            <div className="row">
                {properties.map(item => <div className="col-lg-4 mt-4">
                    <PropertyCard id={item._id} onDelete={() => {
                        setSelectedId(item._id)
                    }} image={item.image} address={item.address} title={item.title} bth={item.no_of_bathrooms} bds={item.no_of_bedrooms} area={item.area_sqft} ClassName={'cancel'} />
                </div>)}
            </div>
            <ConfirmationPopup onYes={deleteProperty} message="Do you really want to delete this Property ?" />
        </>
    )
}
