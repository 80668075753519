import React, { useEffect, useState } from 'react'
import EditButton from '../common/Edit_button'
import DetailsViewer from '../common/DetailsViewer'
import EditProfile from '../common/EditProfile'
import { toaster } from '../apis/commonFunctions'
import { getRequest, patchRequest } from '../apis/functions'
import APIS from '../apis/routes'
import { useParams } from 'react-router-dom'

export default function ViewSubAdmin() {
    const [userDetails, setData] = useState({
        name: "",
        email: "",
        profile_pic: "",
        privillages: []
    })

    const [toggle, setToggle] = useState(false);
    const { id } = useParams();
    const [loader,setLoader] = useState(false);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await getRequest(APIS.GET_PROFILE + "/" + id);
                if (data.status) {
                    setData({
                        ...data.data
                    })
                } else {
                    toaster(data.message, false);
                }
            } catch (err) {
                toaster(err.message, false);
            }
        })()
    }, [id]);

    async function updateProfile(details, file) {
        try {
            setLoader(true);
            let formData = new FormData();
            formData.append('name', details.name);
            formData.append('email', details.email);
            for (let item of details.privillages) {
                formData.append('privillages', item);
            }
            if (file) {
                formData.append('profile_pic', file);
            }
            const { data } = await patchRequest(APIS.UPDATE_SUBADMIN_PROFILE + id, formData);
            setLoader(false);
            if (data.status) {
                toaster(data.message, true);
            } else {
                toaster(data.message, false);
            }
        } catch (err) {
            toaster(err.message, false);
        }
    }

    return (
        <>
            <div className="top_bar row align-items-center">
                <div className="col-lg-6">
                    <div className="pagetitle">
                        <button className='d-flex align-items-center'>Profile</button>
                    </div>
                </div>
                <div className="col-lg-6 text-end">
                    <EditButton onClick={(e) => {
                        setToggle(!toggle)
                    }} />
                </div>
            </div>
            <div className="outer_div mt-4">
                {!toggle ? <DetailsViewer withdraw_days={userDetails.withdraw_days} name={userDetails.name} email={userDetails.email} profile_pic={userDetails.profile_pic} privillages={userDetails.privillages} /> :
                    <EditProfile withdraw_days={userDetails.withdraw_days} name={userDetails.name} email={userDetails.email} privillages={userDetails.privillages} profile_pic={userDetails.profile_pic} onUpdateProfile={updateProfile} loader={loader} />}
            </div>
        </>
    )
}
